export type MenuItem = {
  key: string;
  url?: string;
  isExternal?: boolean;
  subItems?: MenuItem[];
  isSelected?: boolean;
  showInDesktop?: boolean;
  isAction?: boolean;
  hasNewTag?: boolean;
  widthSubMenu?: number;
};

export enum MenuKey {
  Members = 'members',
  Workspaces = 'workspaces',
  Locations = 'locations',
  Partnerships = 'partnerships',
  Ressources = 'ressources',
  User = 'user',
  Account = 'account',
  Locale = 'locale',
}

export const STANDARD_MENU_ITEMS: MenuItem[] = [
  {
    key: 'workspaces',
    widthSubMenu: 220,
    subItems: [
      {
        key: 'solutions',
        url: '/solutions',
      },
      {
        key: 'book-meeting-room',
        url: '/meeting-rooms',
      },
    ],
  },
  {
    key: 'locations',
    url: '/locations',
  },
  {
    key: 'partnerships',
    subItems: [
      {
        key: 'enterprise',
        url: '/enterprise',
      },
      {
        key: 'landlords',
        url: '/landlords',
      },
      {
        key: 'brokers',
        url: '/brokers',
      },
    ],
  },
  {
    key: 'ressources',
    subItems: [
      {
        key: 'blog',
        url: '/blog',
      },
      {
        key: 'faqs',
        url: '/faq',
      },
    ],
  },
];
