'use client';

import { useState } from 'react';

import { useUserLocalization } from '@/hooks/useUserLocalization';
import { MicroLocationDto } from '@/lib/locations/dto/micro-location.dto';
import { fetchNearestLocation } from '@/lib/locations/location-api';

function useIndustriousNearestLocation({ locale }: { locale: string }) {
  const [nearestLocation, setNearestLocation] = useState<MicroLocationDto>();
  useUserLocalization({
    onUserLocalizationChange: (userGeoLoc) => {
      const {
        lnglat: { lat, lng },
      } = userGeoLoc;

      const fetchNearestLoc = async () => {
        const nearestLocation = await fetchNearestLocation({
          locale,
          latitude: lat,
          longitude: lng,
        });
        setNearestLocation(nearestLocation);
      };

      fetchNearestLoc();
    },
  });

  return nearestLocation;
}

export default useIndustriousNearestLocation;
