'use client';

import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslations } from 'next-intl';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

import useIndustriousNearestLocation from '@/hooks/useIndustriousNearestLocation';
import { useToastError } from '@/hooks/useToastError';
import { formNames } from '@/lib/forms/types';
import { STRONG_EMAIL_REGEXP } from '@/utils/constants';
import * as gtm from '@/utils/gtm';

import { Input } from '../form/Input/Input';
import { Select } from '../form/Select/Select';
import { SelectOption } from '../form/Select/types';
import Title from './ListTitle';
import Section from './Section';

const newsletterFormSchema = yup
  .object({
    email: yup
      .string()
      .trim()
      .required('email-required')
      .email('email-invalid')
      .matches(STRONG_EMAIL_REGEXP, 'email-invalid'),
    location: yup.string().trim().required('location-required'),
  })
  .required();

export type NewsletterFormData = yup.InferType<typeof newsletterFormSchema>;

export default function Newsletter({
  locationOptions,
  locale,
}: {
  locationOptions: SelectOption[];
  locale: string;
}) {
  const t = useTranslations('footer.newsletter');
  const nearestLocation = useIndustriousNearestLocation({ locale });
  const { showToast, hideToast } = useToastError();
  const [isSubmitted, setIsSubmitted] = useState(false);

  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    formState: { errors, isValid },
  } = useForm<NewsletterFormData>({
    resolver: yupResolver(newsletterFormSchema),
    defaultValues: {
      email: '',
    },
    mode: 'onBlur',
  });

  useEffect(() => {
    if (nearestLocation != null) {
      setValue('location', nearestLocation.sfId);
    }
  }, [nearestLocation]);

  const onSubmit = async () => {
    const result = await fetch('/api/leads/newsletter', {
      method: 'POST',
      body: JSON.stringify({
        ...getValues(),
        formName: formNames.NEWSLETTER_FOOTER,
      }),
    });

    if (result.status !== 200) {
      showToast();
      return;
    }

    gtm.pushEvent('formSubmissionSuccess_Newsletter');
    setIsSubmitted(true);
    hideToast();
  };

  return (
    <Section>
      <Title>{t(isSubmitted ? 'thank-you-title' : 'title')}</Title>
      <div className="flex flex-col mb-5 xl:flex-row gap-y-3">
        <div className="lg:mr-5 md:min-w-[200px]">
          {t(isSubmitted ? 'thank-you-description' : 'description')}
        </div>
        {!isSubmitted && (
          <form
            className="flex flex-col md:flex-row md:items-center"
            onSubmit={handleSubmit(onSubmit)}
          >
            <Input
              data-testid="newsletter-email"
              className="max-h-5 lg:max-w-[300px] mb-4 md:mr-5 md:mb-0 md:w-96 text-teal-5"
              type="email"
              {...register('email')}
              placeholder={t('email-placeholder')}
              hasError={!!errors.email}
              data-lpignore="true"
              required
            />
            <Select
              className="min-h-5 lg:max-w-[350px] text-teal-5 text-s lg:w-96"
              data-testid="newsletter-locations-select"
              {...register('location')}
              options={locationOptions}
              defaultValue={nearestLocation?.sfId}
              hasError={!!errors.location}
            />
            <button
              data-testid="newsletter-submit-btn"
              className="self-end w-40 h-12 mt-4 border-2 border-white rounded-full md:self-auto md:mt-0 sm:ml-5 xl:px-10 hover:bg-white hover:text-teal-40"
              type="submit"
              disabled={!isValid}
            >
              {t('sign-up-button')}
            </button>
          </form>
        )}
      </div>
    </Section>
  );
}
